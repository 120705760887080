import React, { useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { usersData } from "../../utils/data";
import { DataTable } from "primereact/datatable";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getUsersAction } from "../../redux/actions/users/getUsersAction";
import EditUser from "./EditUser";
import { updateUserStatus } from "../../redux/actions/users/updateUserStatus";
import { Toast } from "primereact/toast";
import { clearUpdateUserState } from "../../redux/reducers/updateUserReducer";

const ManageUsers = () => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const abortControllerRef = useRef(null);
  const toast = useRef(null);
  const getUsers = useSelector((state) => state.getUsers);
  const updateUserStatusData = useSelector(
    (state) => state.updateUserStatusData
  );

  useEffect(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    dispatch(
      getUsersAction({ searchValue: "", signal: abortController.signal })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (!!getUsers?.data?.data?.length) {
      setUsers(getUsers.data.data);
    } else {
      setUsers([]);
    }
  }, [getUsers]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);

    // Abort the previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    // Dispatch the action with the search value and abort signal
    dispatch(
      getUsersAction({ searchValue: value, signal: abortController.signal })
    );
  };

  const handleUpdateUser = (rowData) => {
    setOpenModal(true);
    setEditedData(rowData);
  };

  const handleStatusChange = (e, userId) => {
    dispatch(updateUserStatus({ id: userId, isActive: e.target.checked }));
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, isActive: !user.isActive } : user
      )
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <button className="mr-2" onClick={() => handleUpdateUser(rowData)}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </>
    );
  };

  const switchBodyTemplate = (rowdata) => {
    return (
      <>
        <div className="form-check form-switch flex justify-center">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={rowdata?.isActive}
            onChange={(e) => handleStatusChange(e, rowdata?.id)}
            id="flexSwitchCheckDefault"
          />
          <label
            className="form-check-label"
            htmlFor="flexSwitchCheckDefault"
          />
        </div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="form-control form-control-sm ml-1"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  useEffect(() => {
    if (updateUserStatusData?.data?.status) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: updateUserStatusData?.data?.message,
        life: 3000,
      });
      dispatch(clearUpdateUserState());
    } else if (updateUserStatusData?.data?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: updateUserStatusData?.data?.message,
        life: 3000,
      });
      dispatch(clearUpdateUserState());
    } else if (updateUserStatusData?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: updateUserStatusData?.error,
        life: 3000,
      });
      dispatch(clearUpdateUserState());
    }
  }, [updateUserStatusData, dispatch]);

  return (
    <>
      <div className="main-panel">
        <div className="container-fluid p-3">
          <div className="height-100 bg">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className="card caption-top ">
                  <div className="p-3">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6">
                        <h5 className="text-xl">Manage User</h5>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6 text-end">
                        <Link to="/addUser">
                          <button className="btn btn-primary">Add User</button>
                        </Link>
                      </div>
                    </div>
                    <div className="table-card">
                      <DataTable
                        value={users}
                        // filters={filters}
                        removableSort
                        tableStyle={{ minWidth: "50rem" }}
                        header={header}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 30]}
                        totalRecords={usersData.length}
                        emptyMessage="No users found."
                        className="bg-none"
                        showGridlines
                      >
                        <Column
                          className="border-1 text-[14px] p-1 text-center"
                          field="id"
                          header="Sr. No"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center"
                          field="first_name"
                          header="User Name"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center"
                          field="email"
                          header="Email"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center"
                          field="mobile"
                          header="Mobile No"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 py-2 pl-[2rem] text-center"
                          body={switchBodyTemplate}
                          header="Status"
                        ></Column>
                        <Column
                          className="border-1 py-2 pl-[2.5rem] text-[#FE4C4C] text-center"
                          body={actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <EditUser setEditModal={setOpenModal} editedData={editedData} />
      )}
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default ManageUsers;
