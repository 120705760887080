import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { offlineDeviceName } from "../redux/actions/offlineDeviceName";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Tag } from "primereact/tag";

const DeviceStatus = () => {
  const dispatch = useDispatch();
  const [offline, setOffline] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [expandedRows, setExpandedRows] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const offlineData = useSelector((state) => state.offlineDeviceNameReducer);
  const { role } = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    dispatch(offlineDeviceName());
  }, [dispatch]);

  // const expandAll = () => {
  //   let _expandedRows = {};

  //   offline.forEach((p) => (_expandedRows[`${p.id}`] = true));

  //   setExpandedRows(_expandedRows);
  // };

  useEffect(() => {
    if (offlineData?.data?.length) {
      setOffline(offlineData?.data);
      setIsLoading(false);
    }
  }, [offlineData]);

  useEffect(() => {
    if (offlineData?.loading) {
      setIsLoading(true);
    }
  }, [offlineData]);

  const statusColumnBody = (row) => {
    if (row?.deviceStatus === "Online") {
      return <Tag severity="success">Online</Tag>;
    } else if (row?.deviceStatus === "TemporaryOffline") {
      return <Tag severity="warning">Temporary Offline</Tag>;
    } else if (row?.deviceStatus === "LongTermOffline") {
      return <Tag style={{ background: "gray" }}>LongTerm Offline</Tag>;
    } else {
      return <Tag severity="danger">Offline</Tag>;
    }
  };

  // else if (row?.deviceStatus === "TemporaryOffline") {
  //   return <Tag severity="danger">Temporary Offline</Tag>;
  // }
  // else if (row?.deviceStatus === "LongTermOffline") {
  //   return <Tag style={{ background: "gray" }}>LongTerm Offline</Tag>;
  // }

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Link className="mr-2" to={`/details/${rowData?.id}/0/1`}>
          View <FontAwesomeIcon icon={faEye} />
        </Link>
      </>
    );
  };

  const allowExpansion = (rowData) => {
    return Object.keys(rowData).length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data.modules} className="">
          <Column
            header="Sr no"
            body={(_, { rowIndex }) => rowIndex + 1}
            className="text-[14px] p-2 text-center "
          ></Column>
          <Column
            field="moduleName"
            header="Module name"
            className="text-[14px] p-2 text-center "
            sortable
          ></Column>
          {/* only for admin */}
          {role === 1 && (
            <Column
              field="tableName"
              header="Table name"
              className="text-[14px] p-2 text-center "
              sortable
            ></Column>
          )}
          <Column
            field="lastUpdated"
            header="Last Updated"
            className="text-[14px] p-2 text-center"
            sortable
          />
          <Column
            body={statusColumnBody}
            header="Status"
            className="text-[14px] p-2 text-center "
            sortable
          ></Column>
        </DataTable>
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div className="flex justify-between">
      <h5 className="text-xl">Device Status</h5>
      <span className="flex items-center">
        <span className="font-normal">Search:</span>
        <InputText
          className="form-control form-control-sm ml-1"
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
        />
      </span>
    </div>
  );
  // <div className="flex flex-wrap justify-content-end gap-2">
  //   <Button
  //     icon={<FontAwesomeIcon className="mr-2" icon={faMinusSquare} />}
  //     label="Collapse All"
  //     onClick={collapseAll}
  //     text
  //   />
  // </div>;

  return (
    <div className="main-panel">
      <div className="container-fluid py-3">
        <div className="height-100 bg">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div className="card caption-top ">
                <div className="p-3">
                  <div className="card">
                    <DataTable
                      value={isLoading ? [] : offline}
                      expandedRows={expandedRows}
                      onRowToggle={(e) => setExpandedRows(e.data)}
                      rowExpansionTemplate={rowExpansionTemplate}
                      dataKey="id"
                      header={header}
                      filters={filters}
                      tableStyle={{ minWidth: "60rem" }}
                      emptyMessage={
                        isLoading ? (
                          <p className="text-center font-bold">Loading...</p>
                        ) : (
                          "No Data Found"
                        )
                      }
                    >
                      <Column
                        expander={allowExpansion}
                        style={{ width: "5rem" }}
                      />
                      <Column
                        header="Sr no"
                        body={(_, { rowIndex }) => rowIndex + 1}
                        className="text-[14px] p-2 text-center"
                      />
                      <Column
                        field="node"
                        header="Node name"
                        className="text-[14px] p-2 text-center"
                        sortable
                      />
                      <Column
                        field="description"
                        header="Description"
                        className="text-[14px] p-2 text-center"
                        sortable
                      />
                      <Column
                        field="location"
                        header="Location"
                        className="text-[14px] p-2 text-center"
                        sortable
                      />
                      <Column
                        body={actionBodyTemplate}
                        header="Action"
                        className="text-[14px] p-2 text-center"
                      />
                      {/* )} */}
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceStatus;
