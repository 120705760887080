import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { linksData } from "../utils/data";
import { clearLoginState } from "../redux/reducers/loginReducer";
import { useDispatch } from "react-redux";
import { useAudio } from "../AudioProvider";

const Sidebar = ({ showSubTab, handleSubTab, setShowSideBar }) => {
  const { role } = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetAudio } = useAudio();

  const handleLogout = () => {
    dispatch(clearLoginState());
    localStorage.removeItem("auth-token");
    localStorage.removeItem("userData");
    localStorage.removeItem("showPause");
    resetAudio();
    localStorage.clear();
    navigate("/");
  };
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="sidebar-menu">
        <li>
          <div className="card-header border-none pb-1 bg-white">
            <Link
              className="card-link py-0"
              to="/home"
              onClick={() => setShowSideBar(false)}
            >
              Dashboard
            </Link>
          </div>
        </li>
        {/* {role === 0 ? ( */}
        <>
          <li>
            <div className="card-header border-none pb-1 pt-0 bg-white">
              <Link
                to={"/control-panel/all"}
                className="card-link mb-2 py-0"
                onClick={() => setShowSideBar(false)}
              >
                Control Panel
              </Link>
            </div>
          </li>
          <li>
            <div className="card-header border-none pb-1 pt-0 bg-white">
              <Link
                to={"/device-list"}
                className="card-link mb-2 py-0"
                onClick={() => setShowSideBar(false)}
              >
                Device Lists
              </Link>
            </div>
          </li>
          <li>
            <div className="card-header border-none pb-1 pt-0 bg-white">
              <Link
                to="/device-status"
                className="card-link mb-2 py-0"
                onClick={() => setShowSideBar(false)}
              >
                Devices Status
              </Link>
            </div>
          </li>
          {role === 0 && (
            <li>
              <div className="w-full">
                <div className="card-header border-none py-0 bg-white">
                  <Link
                    className="card-link py-0 text-[1rem]"
                    onClick={() => handleSubTab("tab1")}
                  >
                    System Settings
                    <div className="float-right">
                      <FontAwesomeIcon
                        icon={showSubTab?.tab1 ? faChevronUp : faChevronDown}
                      />
                    </div>
                  </Link>
                </div>
                {showSubTab?.tab1 && (
                  <div className="card-body pt-1 ms-3 text-md">
                    <div className="row">
                      {linksData?.map((link, index) => (
                        <div
                          key={index}
                          className="col-lg-12 col-md-12 col-sm-12 col-12 mb-1"
                        >
                          <Link
                            to={link.to}
                            className="card-link"
                            onClick={() => setShowSideBar(false)}
                          >
                            {link.label}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </li>
          )}
          <div
            className="dropdown-item card-link  text-[#FE4C4C] cursor-pointer md:hidden block"
            onClick={handleLogout}
          >
            Logout
          </div>
        </>
      </ul>
    </nav>
  );
};

export default Sidebar;
