import React, {
  createContext,
  useRef,
  useContext,
  useState,
  useEffect,
} from "react";

const AudioContext = createContext();

// Create a provider component
export const AudioProvider = ({ children }) => {
  const audioRef = useRef(null);
  const localStorageValue =
    localStorage.getItem("showPause") === "1" ? true : false;
  const [isStopped, setIsStopped] = useState(localStorageValue);

  const playAudio = () => {
    if (audioRef.current && !isStopped) {
      audioRef.current.play();

      // if (playPromise !== undefined) {
      //   playPromise.catch((error) => {
      //     console.log("Playback failed due to user interaction issue:", error);
      //   });
      // }
    }
  };

  const resetAudio = () => {
    setIsStopped(false);
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsStopped(true);
    }
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "showPause") {
        if (event.newValue !== null) {
          setIsStopped(event.newValue === "1" ? true : false);
        } else {
          setIsStopped(false);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <AudioContext.Provider
      value={{ playAudio, resetAudio, stopAudio, audioRef, isStopped }}
    >
      <audio preload="auto" ref={audioRef} src="buzzer.mp3" loop />
      {children}
    </AudioContext.Provider>
  );
};

// Custom hook to use the audio context
export const useAudio = () => {
  return useContext(AudioContext);
};
