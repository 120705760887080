import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearLoginState } from "../redux/reducers/loginReducer";
import { useAudio } from "../AudioProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { navLinks } from "../utils/data";

const Navbar = ({ toggleMenuContent }) => {
  const [profileMenu, setProfileMenu] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { stopAudio, resetAudio, isStopped } = useAudio();
  const [stop, setStop] = useState(isStopped);

  useEffect(() => {
    setStop(isStopped);
  }, [isStopped]);

  const handleLogout = () => {
    dispatch(clearLoginState());
    localStorage.removeItem("auth-token");
    localStorage.removeItem("userData");
    localStorage.removeItem("showPause");
    resetAudio();
    localStorage.clear();
    navigate("/");
  };

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { name } = userData || {};

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfileMenu(false);
        // setNotificationList(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-md bg-white navbar-light col-lg-12 col-12 p-0 fixed-top md:justify-between  flex flex-row">
      <div className="navbar-brand-wrapper flex items-center justify-between">
        <Link className="navbar-brand brand-logo m-2" to="/home">
          <img src="https://embelink.com/include/images/1.png" alt="logo" />
        </Link>
        <div className="md:block hidden justify-end border-0 cursor-pointer">
          <button
            className="border-0"
            type="button"
            data-toggle="minimize"
            onClick={toggleMenuContent}
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </div>
      <div className="flex">
        <div className="navbar-menu-wrapper md:flex items-center justify-end px-0">
          <div className="md:block hidden mr-2">
            <div className="navbar-nav navbar-nav flex-row">
              {navLinks.map((link, index) => (
                <div className="nav-item dropdown flex" key={index}>
                  <Link className="nav-link !text-[16px]" to={link.path}>
                    {link.label}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex justify-center items-center border-1 border-red-500 rounded-3xl p-3 h-[35px] w-[35px] cursor-pointer"
        onClick={() => {
          stopAudio();
          setStop(true);
          localStorage.setItem("showPause", 1);
        }}
      >
        {stop ? (
          <FontAwesomeIcon icon={faVolumeMute} className="text-gray-400" />
        ) : (
          <FontAwesomeIcon icon={faPause} />
        )}
      </div>
      <div className="flex">
        <div className="navbar-menu-wrapper md:flex items-center justify-end px-0">
          <div className="md:block hidden">
            <div className="navbar-nav navbar-nav navbar-nav-right flex-row">
              <div className="nav-item dropdown" ref={dropdownRef}>
                <Link
                  className="nav-link dropdown-toggle arrow-hide !text-[16px]"
                  onClick={() => setProfileMenu((prev) => !prev)}
                >
                  Welcolme, {name}
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    profileMenu ? "show left-8" : ""
                  }`}
                >
                  <Link className="dropdown-item text-[#FE4C4C]" to="/home">
                    Home
                  </Link>
                  {/* <Link className="dropdown-item text-[#FE4C4C]" to="/">
                    Setting
                  </Link> */}
                  <div
                    className="dropdown-item text-[#FE4C4C] cursor-pointer"
                    onClick={handleLogout}
                  >
                    Logout
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="border-0 block md:hidden mr-3"
          type="button"
          data-toggle="minimize"
          onClick={toggleMenuContent}
        >
          <span className="navbar-toggler-icon" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
